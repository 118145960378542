import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as style from './research.module.scss';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdjust } from "@fortawesome/free-solid-svg-icons"

const Research = ({ imagePath, title, description, link, tag, date }) => {

    const tagColors = {
        'Paper': 'green',
        'Poster': 'yellow',
        'Technical Report': 'blue'
    }

    return (
    <div className={style.card}>
        <div className={`tag ${style.tag} ${tagColors[tag]}`}><span>{tag}</span></div>
        <h4>{title}</h4>
        <p>{description}</p>
        {link && <Link to={link}><span className={`link data-hover`}>Project Details</span></Link>}
    </div>
    )
}

Research.propTypes = {
    imagePath: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    link: PropTypes.string,
    date: PropTypes.string,
}

Research.defaultProps = {
    imagePath: ``,
    title: `Project Title`,
    description: ``,
    tag: ``,
    date: `Not specified`,
}

export default Research
