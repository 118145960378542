const research = {
    aesop: {
        imagePath: ``,
        tag: `Poster`,
        title: `Aesop: Authoring Engaging Digital Storytelling Experiences`,
        description: `Presented at the 32nd User Interface Software and Technology Symposium 2019.`,
        link: `/projects/aesop`,
        date: `October 2019`
    },
    postureAnalysis: {
        imagePath: ``,
        tag: `Technical Report`,
        title: `Wearable Technology for Posture Analysis`,
        description: `Presented as my Final Year Bachelor's Thesis.`,
        link: `/projects/posture-analysis`,
        date: `March 2019`
    },
    signLanguageTranslation: {
        tag: `Technical Report`,
        title: `Real-time American Sign Language Translation`,
        description: `Presented as my Final Year Minor Thesis`,
        link: `/projects/sign-language-translation`,
        date: `October 2018`
    },
    fishClassification: {
        tag: `Paper`,
        title: `Classification of Fish Species using Deep Learning based Hybrid Model`,
        description: `Our proposed deep convolutional networks driven classification algorithm showed a substantial improvement (95.85% accuracy) over existing classification methods`,
        date: `2018`
    }
};

export { research }