import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Research from "../components/research/research"
import Seo from "../components/seo"

import { research } from "../components/data/research";

const ResearchPage = () => {

    // const [ selection, setSelection ] = useState([]);
    // const [ projectList, setProjectList ] = useState(Object.keys(research));

    return (
        <>
            <Seo title="Research" />
            <section className={`fix-header research-section`}>
             <div className={`content`}>
               <h6 className={`primary center`}>Stories from the lab</h6>
               <h1 className={`center`}>Research Work</h1>
              <div className={`content--md-center`}>
                 <p className={`primary center`}>Here's my brief experience participating in research-like work. I enjoy working on and reading about novel techniques to solve and understand problems.</p>
               </div>
             </div>
            </section>
            <section className={`fix-bottom`}>
             <div className={`content`}>
                <div className={`research-wrapper`}>
                    {Object.keys(research).map((projectName) => (
                      <Research
                      imagePath={research[projectName].imagePath}
                      title={research[projectName].title}
                      description={research[projectName].description}
                      tag={research[projectName].tag}
                      link={research[projectName].link}
                      date={research[projectName].date}
                      key={projectName}
                      />
                    ))}
             </div>
            </div>
         </section>
        </>
    )
}

export default ResearchPage
